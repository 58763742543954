import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AutoLoginGuard } from './guards/autologin.guard';
import { CandidatesResolverService } from './resolvers/candidatesresolver.service';
import { WayspotsResolverService } from './resolvers/wayspotsresolver.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AutoLoginGuard]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'fieldplan',
    loadChildren: () => import('./fieldplan/fieldplan.module').then( m => m.FieldplanPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'poi',
    loadChildren: () => import('./poi/poi.module').then( m => m.PoiPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then( m => m.MapPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'preview',
    loadChildren: () => import('./preview/preview.module').then( m => m.PreviewPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'wayspot/:id',
    loadChildren: () => import('./wayspot/wayspot.module').then( m => m.WayspotPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'candidate/:id',
    loadChildren: () => import('./candidate/candidate.module').then( m => m.CandidatePageModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
