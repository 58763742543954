import { Injectable } from '@angular/core';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { doc, docData, Firestore, setDoc, updateDoc } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentUserData = null;
  logout$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private firestore: Firestore,
    private auth: Auth) {
      
      onAuthStateChanged(this.auth, user => {      
        if (user) {
          const userDoc = doc(this.firestore, `users/${user.uid}`);
          docData(userDoc, { idField: 'id' }).pipe(
            takeUntil(this.logout$)
          ).subscribe(data => {
            this.currentUserData = data;
          })
        } else {
          this.currentUserData = null;
        }
      })
  
    }

  getCurrentUser() {
    return this.auth.currentUser;
  }

  getWayfinderName() {
    return this.currentUserData.wayfinderName;
  }

  getUid() {
    return this.auth.currentUser.uid;
  }

  getAvatarUrl() {
    return this.auth.currentUser?.photoURL;
  }

  save(data) {
    const user = {
      wayfinderName: data.wayfinderName
    }
    let docRef = doc(this.firestore, 'users/'+this.currentUserData.id);
    return setDoc(docRef, user, { merge: true });

  }

}
